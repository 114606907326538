function CheckAchievementIcon({ className }: { className: string }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M2425 5106 c-87 -20 -152 -48 -315 -134 -190 -101 -225 -115 -415
-174 -407 -125 -523 -236 -640 -613 -66 -216 -86 -263 -175 -430 -90 -169
-133 -273 -149 -359 -34 -183 8 -336 171 -631 62 -114 88 -179 153 -390 79
-251 154 -373 288 -462 92 -62 179 -99 377 -158 161 -49 184 -59 440 -192 348
-180 508 -167 945 75 85 47 162 76 360 138 370 115 484 229 600 599 67 216 91
277 156 395 165 299 204 450 164 640 -20 93 -58 184 -148 350 -82 152 -106
209 -172 425 -115 368 -232 485 -600 600 -204 63 -270 89 -378 147 -268 146
-355 178 -502 184 -64 2 -122 -1 -160 -10z m315 -601 c144 -22 233 -50 365
-114 265 -130 457 -325 586 -595 188 -395 150 -857 -101 -1232 -68 -101 -223
-256 -324 -324 -379 -254 -840 -289 -1241 -96 -499 241 -783 770 -700 1306 22
144 50 233 114 365 71 145 152 256 262 360 285 267 664 388 1039 330z"
        />
        <path
          d="M2393 4205 c-293 -53 -552 -246 -681 -508 -171 -347 -124 -740 123
-1040 112 -135 310 -260 490 -309 116 -31 355 -31 470 0 186 51 376 172 495
314 73 88 162 262 192 373 28 107 31 345 5 449 -125 497 -601 811 -1094 721z
m547 -593 c70 -35 102 -131 66 -200 -8 -15 -119 -131 -248 -258 -248 -247
-258 -254 -336 -239 -30 5 -62 31 -165 133 -71 70 -135 139 -143 154 -29 56
-8 152 41 184 78 51 143 37 235 -52 l66 -63 164 165 c91 91 179 172 195 180
41 18 84 17 125 -4z"
        />
        <path
          d="M1630 815 c0 -421 4 -661 11 -694 17 -81 99 -136 173 -117 13 3 153
67 310 142 l286 137 -2 435 -3 434 -70 18 c-111 28 -219 74 -370 156 -137 75
-276 134 -315 134 -20 0 -20 -10 -20 -645z"
        />
        <path
          d="M3370 1431 c-41 -16 -133 -61 -205 -100 -179 -98 -244 -124 -442
-177 -10 -3 -13 -99 -13 -437 l0 -433 288 -139 c252 -122 293 -139 339 -139
59 -1 96 20 128 75 19 33 20 55 23 707 l3 672 -23 0 c-13 -1 -57 -14 -98 -29z"
        />
      </g>
    </svg>
  );
}

function CertificateIcon({ className }: { className: string }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M681 4624 c-169 -45 -301 -180 -346 -351 -23 -87 -22 -2540 0 -2628
45 -172 179 -305 352 -350 49 -13 197 -15 1005 -15 l948 0 0 77 c0 72 -3 83
-50 177 -28 56 -62 146 -77 201 -23 89 -26 118 -26 260 0 141 3 172 26 257 98
367 376 646 747 749 75 21 106 24 260 24 207 0 282 -16 445 -94 287 -137 489
-387 568 -700 17 -70 21 -115 21 -241 -1 -136 -4 -167 -27 -255 -15 -55 -49
-145 -77 -201 -47 -94 -50 -105 -50 -177 0 -42 2 -77 5 -77 23 0 137 47 179
74 100 64 184 189 206 305 6 34 10 509 10 1305 0 1092 -2 1259 -15 1311 -45
172 -179 305 -352 350 -85 22 -3669 21 -3752 -1z m3405 -970 c53 -39 69 -71
69 -134 0 -63 -16 -95 -69 -134 -27 -21 -31 -21 -1504 -24 -1017 -2 -1488 0
-1514 8 -101 29 -140 168 -71 251 53 62 -48 59 1575 56 1484 -2 1487 -2 1514
-23z m-1760 -960 c53 -39 69 -71 69 -134 0 -63 -16 -95 -69 -134 -27 -21 -39
-21 -624 -24 -401 -2 -609 0 -634 8 -101 29 -140 168 -71 251 51 60 32 59 695
56 595 -2 607 -2 634 -23z"
        />
        <path
          d="M3380 2706 c-155 -34 -256 -89 -370 -205 -67 -67 -95 -105 -128 -171
-62 -125 -77 -192 -76 -340 1 -107 5 -137 27 -205 38 -115 90 -199 182 -290
93 -93 160 -135 282 -178 80 -28 100 -31 213 -31 148 -1 216 14 338 76 146 73
275 213 338 368 157 386 -52 829 -451 956 -100 32 -258 40 -355 20z"
        />
        <path
          d="M2962 845 l3 -272 27 -35 c34 -44 102 -73 150 -64 18 4 112 49 208
100 l175 93 90 -47 c278 -147 266 -142 318 -137 51 4 88 26 121 71 20 26 21
43 24 295 l3 268 -98 -48 c-54 -27 -143 -61 -198 -76 -89 -23 -118 -26 -265
-26 -147 0 -176 3 -265 26 -55 15 -144 49 -198 76 l-98 48 3 -272z"
        />
      </g>
    </svg>
  );
}

function CheckBadgeRectIcon({ className }: { className: string }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M1018 5105 c-513 -80 -925 -496 -1003 -1012 -22 -147 -22 -2919 0
-3066 78 -521 492 -934 1012 -1012 147 -22 2920 -22 3066 0 522 79 934 491
1012 1014 22 142 22 2919 1 3061 -81 536 -500 947 -1039 1019 -127 18 -2937
13 -3049 -4z m3110 -413 c280 -86 484 -292 564 -569 l23 -78 0 -1485 c0 -1411
-1 -1488 -18 -1550 -82 -288 -291 -500 -574 -582 l-78 -23 -1485 0 c-1411 0
-1488 1 -1550 18 -288 82 -500 291 -582 574 l-23 78 0 1485 0 1485 23 78 c90
312 354 546 667 590 17 2 689 4 1495 3 l1465 -1 73 -23z"
        />
        <path
          d="M2501 3959 c-13 -5 -55 -38 -93 -74 -82 -76 -165 -132 -263 -180
-198 -96 -384 -127 -690 -116 -115 4 -147 -6 -190 -63 -38 -50 -48 -132 -48
-396 -1 -442 70 -778 233 -1105 176 -354 427 -601 785 -771 136 -64 260 -104
327 -104 112 0 406 127 585 253 115 81 301 268 386 387 136 193 252 465 311
730 46 209 60 345 59 600 0 272 -10 356 -48 406 -44 57 -76 68 -190 63 -300
-12 -487 19 -688 116 -100 47 -182 104 -264 180 -39 35 -83 69 -97 75 -33 12
-83 12 -115 -1z m636 -1002 c65 -44 94 -149 59 -215 -22 -41 -577 -590 -609
-602 -36 -13 -88 -13 -124 1 -36 13 -378 353 -398 395 -59 126 62 270 198 233
33 -9 65 -33 152 -120 l110 -108 215 213 c157 156 225 217 250 225 47 14 108
5 147 -22z"
        />
      </g>
    </svg>
  );
}

function VoidBadgeIcon({ className }: { className: string }) {
  return (
    <svg
      width="183"
      height="86"
      viewBox="0 0 183 86"
      fill="currentColor"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M124.728 63V23.072H142.026C146.077 23.072 149.487 23.754 152.256 25.118C155.026 26.4407 157.134 28.59 158.58 31.566C160.027 34.5007 160.75 38.4067 160.75 43.284C160.75 50.1454 159.159 55.1467 155.976 58.288C152.835 61.4294 148.185 63 142.026 63H124.728ZM134.71 55.312H140.662C142.812 55.312 144.61 55.002 146.056 54.382C147.544 53.7207 148.66 52.522 149.404 50.786C150.19 49.05 150.582 46.5494 150.582 43.284C150.582 39.9774 150.231 37.4354 149.528 35.658C148.867 33.8394 147.792 32.5787 146.304 31.876C144.858 31.132 142.977 30.76 140.662 30.76H134.71V55.312Z"
        fill="currentColor"
      />
      <path d="M108.441 63V23.072H118.423V63H108.441Z" fill="currentColor" />
      <path
        d="M83.449 63.62C76.9597 63.62 72.0203 61.9667 68.631 58.66C65.2417 55.3534 63.547 50.2074 63.547 43.222C63.547 35.8234 65.2417 30.512 68.631 27.288C72.0203 24.064 76.9597 22.452 83.449 22.452C89.9383 22.452 94.8777 24.064 98.267 27.288C101.656 30.512 103.351 35.8234 103.351 43.222C103.351 50.2074 101.656 55.3534 98.267 58.66C94.8777 61.9667 89.9383 63.62 83.449 63.62ZM83.449 55.126C86.797 55.126 89.2357 54.1754 90.765 52.274C92.3357 50.3314 93.121 47.314 93.121 43.222C93.121 38.758 92.3357 35.6167 90.765 33.798C89.2357 31.938 86.797 31.008 83.449 31.008C80.0597 31.008 77.5797 31.938 76.009 33.798C74.4797 35.6167 73.715 38.758 73.715 43.222C73.715 47.314 74.4797 50.3314 76.009 52.274C77.5797 54.1754 80.0597 55.126 83.449 55.126Z"
        fill="currentColor"
      />
      <path
        d="M36.174 63L20.922 23.072H31.338L41.506 51.592L51.736 23.072H62.214L46.962 63H36.174Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M163 10H20C14.4772 10 10 14.4772 10 20V66C10 71.5228 14.4772 76 20 76H163C168.523 76 173 71.5229 173 66V20C173 14.4772 168.523 10 163 10ZM20 0C8.9543 0 0 8.9543 0 20V66C0 77.0457 8.95431 86 20 86H163C174.046 86 183 77.0457 183 66V20C183 8.95431 174.046 0 163 0H20Z"
        fill="currentColor"
      />
    </svg>
  );
}

export {
  CertificateIcon,
  CheckAchievementIcon,
  CheckBadgeRectIcon,
  VoidBadgeIcon,
};
