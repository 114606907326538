import {
  useConsumerProfile,
  getFirstName,
  CONTACT_OPTIONS,
  Sage_CreateConsumerFormDataShape,
} from '@rabbit/bizproc/react';
import { ButtonLink, Input, Button } from '@rabbit/elements/shared-components';
import { Address } from '@rabbit/data/types';
import {
  useAppInfo,
  printAddress,
  getLastNames,
} from '@rabbit/sage/utils/helpers';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { Formik, Form } from 'formik';
import { Dispatch, SetStateAction, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { nestApiCreateConsumer } from '@rabbit/bizproc/core';
import { useTranslation } from 'react-i18next';

export const ModalNewEditCustomerDefault = ({
  customerDetails,
  handleClose,
  handleDelete,
  isSubmitting,
  setIsSubmitting,
  setStep,
  addresses,
  selectedAddress,
  onValuesChange,
  values,
}: {
  customerDetails: any;
  handleClose: () => void;
  handleDelete: () => void;
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  setStep: Dispatch<SetStateAction<'default' | 'address'>>;
  addresses: Address[] | [];
  selectedAddress: Address | undefined;
  values?: Sage_CreateConsumerFormDataShape;
  onValuesChange: (values: Sage_CreateConsumerFormDataShape) => void;
}) => {
  const appInfo = useAppInfo();
  const navigate = useNavigate();
  const { editConsumerProfile } = useConsumerProfile(
    customerDetails?.docid ?? null
  );
  const { t } = useTranslation();
  const [submitAction, setSubmitAction] = useState<string | undefined>('save');
  const isNewCustomer = customerDetails?.docid ? false : true;

  const schema = Yup.object({
    first_name: Yup.string().required(t('message.pleaseEnterFirstName')),
    last_name: Yup.string().trim().required(t('message.pleaseEnterLastName')),
    email: Yup.string()
      .trim()
      .required('Required')
      .email(t('message.pleaseEnterAValidEmailAddress')),
    phone_number: Yup.string()
      .trim()
      .required(t('message.pleaseEnterAPhoneNumber')),
    preferred_contact: Yup.array(),
  });

  const validationSchema = submitAction === 'delete' ? Yup.object({}) : schema;
  const address = selectedAddress
    ? selectedAddress
    : customerDetails?.address?.length
    ? customerDetails?.address.find((i: Address) => i.isDefault) ??
      customerDetails?.address[0]
    : null;
  const initialValues = values || {
    first_name: customerDetails?.splitname?.first
      ? customerDetails?.splitname.first
      : customerDetails?.fullname
      ? getFirstName(customerDetails.fullname)
      : '',
    last_name: customerDetails?.splitname?.last
      ? customerDetails?.splitname.last
      : customerDetails?.fullname
      ? getLastNames(customerDetails.fullname)
      : '',
    email: customerDetails?.email ? customerDetails.email : '',
    phone_number: customerDetails?.phone ? customerDetails.phone : '',
    phone_number_e164: customerDetails?.phone ? customerDetails.phone : '',
    addresses: addresses,
    preferred_contact: customerDetails?.preferred_contact || [],
  };

  const formikRef = useRef(null) as any;

  return (
    <Formik
      onSubmit={async (values) => {
        // Dumped this in here. Check how it interacts with the rest of the modal
        setIsSubmitting(true);
        const data = {
          ...values,
          tenantLink: t('tenantLink'),
          phone_number:
            (values as any).phone_number_e164 ?? values.phone_number,
        };
        let res;
        if (isNewCustomer) {
          try {
            res = await nestApiCreateConsumer(data) as any;
            if (!res) {
              toast.error(t('message.somethingWentWrongPleaseTryAgain'));
              setIsSubmitting(false);
              return;
            }

            handleClose();
            setSubmitAction('');
          } catch (error: any) {
            const errMsg = error?.response?.data.message;
            setIsSubmitting(false);
            toast.error(t(errMsg) || t('message.somethingWentWrongPleaseTryAgain'));
            return;
          }
        }

        if (!isNewCustomer) {
          await editConsumerProfile(data);
          //window.location.reload();
        }

        if (submitAction === 'save') {
          handleClose();
          setSubmitAction('');
          toast.success(
            isNewCustomer
              ? t('message.newCustomerCreatedSuccessfully')
              : t('message.customerDetailsEditedSuccessfully')
          );

          if (isNewCustomer && res?.consumer_private?.docid)
            navigate(
              SAGE_ROUTE_NAME.MANAGE +
                '/customer/' +
                res?.consumer_private?.docid
            );
        } else if (submitAction === 'delete') {
          handleDelete();
          setSubmitAction('');
        }
      }}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={false}
      innerRef={formikRef}
    >
      {(props) => {
        onValuesChange(props.values);
        return (
          <Form className="mt-2 w-full px-5">
            <div className="w-full py-2">
              <div className="flex w-full gap-4">
                <Input
                  type="text"
                  label={t('general.firstName')}
                  name="first_name"
                  settings={{
                    id: 'first_name',
                    hint: '*required',
                    forceUpperCaseFirstLetter: true,
                    placeholder: 'Enter customer first name',
                  }}
                />
                <Input
                  type="text"
                  label={t('general.lastName')}
                  name="last_name"
                  settings={{
                    id: 'last_name',
                    hint: '*required',
                    forceUpperCaseFirstLetter: true,
                    placeholder: 'Enter customer last name',
                  }}
                />
              </div>
            </div>
            <div className="py-2">
              <Input
                type="email"
                label={t('general.email')}
                name="email"
                settings={{
                  id: 'email',
                  disabled: !isNewCustomer ? true : false,
                  placeholder: 'Enter customer email',
                  hint: '*required',
                }}
              />
            </div>
            <div className="flex w-full gap-4 py-2">
              <Input
                type="phone"
                name="phone_number"
                label={t('general.phoneNumber')}
                settings={{
                  placeholder: 'Phone number',
                  hint: '*required',
                }}
              />
              <Input
                type="select"
                name="preferred_contact"
                label={t('general.preferredContactMethod')}
                settings={{
                  isMulti: true,
                  id: 'preferred_contact',
                  placeholder: 'Please select',
                  options: CONTACT_OPTIONS,
                }}
              />
            </div>
            <div className="mt-4">
              <ButtonLink
                label={t('general.address')}
                onClick={() => setStep('address')}
              >
                {!address ? (
                  <span className="italic text-gray-400">
                    No address to display
                  </span>
                ) : (
                  printAddress(address)
                )}
              </ButtonLink>
            </div>
            <div className="mt-4 flex gap-5 py-2">
              <div>
                <Button
                  loading={isSubmitting}
                  kind="primary"
                  size="sm"
                  onClick={() => {
                    setSubmitAction('save');
                    props.handleSubmit();
                  }}
                  children={t('general.saveAll')}
                />
              </div>
              {/* TODO: Delete customer is not implemented yet -VP */}
              {/* {customerDetails ? (
                <div>
                <ButtonIcon
                kind="bgRed"
                label="Delete customer"
                Icon={TrashIcon}
                iconLeft={true}
                onClick={() => {
                setSubmitAction('delete');
                props.handleSubmit();
                }}
                />
                </div>
                ) : null} */}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
