import {
  COVERAGE_OPTIONS,
  LIST_COUNTRIES,
  LIST_CURRENCIES,
  STATIONS,
} from '@rabbit/bizproc/react';
import axios from 'axios';
import {
  format,
  formatDuration,
  fromUnixTime,
  isToday,
  isYesterday,
} from 'date-fns';
import { FirebaseAuthProviderList } from './consts';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Address, DTHoldingProxy, Warranty } from '@rabbit/data/types';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { parse } from 'tinyduration';
import { ConsumerHoldingSummaryShape } from '@rabbit/bizproc/client';
import moment from 'moment';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import React from 'react';

/* -------------------------------------------------------------------------- */
/*                          Date handling functions                           */
/* -------------------------------------------------------------------------- */

export const formatUnixTime = (
  unixTimestamp: number | any,
  formatStr: string
) => {
  // If the timestamp is an object, extract the seconds property -VP
  const fixedTimestamp = unixTimestamp.seconds
    ? unixTimestamp.seconds
    : unixTimestamp;
  unixTimestamp = Math.round(fixedTimestamp);
  // If the timestamp is in milliseconds, convert it to seconds -VP
  const timestamp =
    unixTimestamp.toString().length === 13
      ? Math.round(unixTimestamp / 1000)
      : unixTimestamp;
  if (formatStr === 'h:mmaaa')
    return format(fromUnixTime(timestamp), formatStr);
  if (formatStr) return format(fromUnixTime(timestamp), formatStr);
  if (isToday(fromUnixTime(timestamp))) return 'Today';
  if (isYesterday(fromUnixTime(timestamp))) return 'Yesterday';
  return format(fromUnixTime(timestamp), 'dd/MM/yyyy');
};

/* -------------------------------------------------------------------------- */
/*                               Formik helpers                               */
/* -------------------------------------------------------------------------- */

/** Changes Formik's default validation to output an array of errors rather than the first one to be found. */
export const asyncValidateSchema = (schema: any) => (values: any) =>
  schema
    .validate(values, { abortEarly: false, strict: false })
    .then(() => ({}))
    .catch(({ inner }: any) =>
      inner.reduce(
        (memo: any, { path, message }: any) => ({
          ...memo,
          [path]: (memo[path] || []).concat(message),
        }),
        {}
      )
    );

/* -------------------------------------------------------------------------- */
/*                                Misc helpers                                */
/* -------------------------------------------------------------------------- */

/** Handles password popover data for displaying validation progress in UI, used with @PasswordValidationPopover component */
export const handlePopoverData = (
  errors: undefined | string | string[],
  passValue: string
) => {
  if (!passValue) return ['noPass'];
  if (!errors) return ['noErr'];

  return [...errors];
};

/** Deep clones an object, preserving all its nested data in its original format
 * NOTE: This will actually convert some more rarely used data types to null
 * (Symbol and possibly functions), so it might be worth revisiting in the future.
 * Might be worth copying a lodash fn for this or checking out the new structuredClone method.
 * One for the future -dc
 */
export const deepClone = (obj: Record<string, any>) => {
  return JSON.parse(JSON.stringify(obj));
};

export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const extractFileNameFromUrl = (url: string | URL) => {
  const { pathname } = new URL(url);
  return pathname.split('/').pop();
};

export const getStationByKey = (key: string, t: any = null) => {
  return (
    STATIONS(t).find((station) => station.key === key) ?? {
      id: 0,
      label: '-',
      key: '',
    }
  );
};

export const generateRandomId = () => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyz';
  let result = '2023-';
  for (let i = 0; i < 5; i++) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

export const timestampToTimeAgo = (timestamp: number) => {
  const msPerSecond = 1000;
  const msPerMinute = msPerSecond * 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;

  const timeElapsed = Date.now() - timestamp;

  if (timeElapsed < msPerMinute) {
    const seconds = Math.round(timeElapsed / msPerSecond);
    return `${seconds}sec`;
  } else if (timeElapsed < msPerHour) {
    const minutes = Math.round(timeElapsed / msPerMinute);
    return `${minutes}min`;
  } else if (timeElapsed < msPerDay) {
    const hours = Math.round(timeElapsed / msPerHour);
    return `${hours}h`;
  } else {
    const days = Math.round(timeElapsed / msPerDay);
    return `${days}d`;
  }
};

export const getCountryByLabel = (label: string) => {
  return (
    LIST_COUNTRIES.find(
      (country) => country.label === label || country.original === label
    ) ?? null
  );
};

export const getCountryByValue = (value: string) => {
  return LIST_COUNTRIES.find((country) => country.value === value) ?? null;
};

export const getCoverageByValue = (value: string) => {
  return COVERAGE_OPTIONS.find((coverage) => coverage.value === value) ?? null;
};

export const getCurrencyByCode = (code: string) => {
  return LIST_CURRENCIES.find((currency) => currency.code === code) ?? null;
};

export const handleKeyPress = (e: React.KeyboardEvent) => {
  const { key } = e;
  const regex = /^[ A-Za-z0-9&-]+$/;
  if (!regex.test(key)) {
    e.preventDefault();
  }
};

export const forceUpperCaseFirstLetter = (e: React.KeyboardEvent) => {
  const element = e.target as HTMLInputElement;
  const regex = /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/;
  if (!regex.test(element.value) && element.value.length > 0) {
    element.value = element.value[0].toUpperCase() + element.value.slice(1);
  }
};

/* -------------------------------------------------------------------------- */
/*                            Addresses API calls                             */
/* -------------------------------------------------------------------------- */
export const onPostCodeChange = async (
  value: string,
  key: string,
  country = 'aus'
) => {
  const response = await axios({
    url: 'https://api.craftyclicks.co.uk/address/1.1/find',
    data: {
      key: key,
      query: value,
      country: country,
      extra: {
        //"best_match_only": true,
        no_groupings: true,
      },
    },
    method: 'POST',
    responseType: 'stream',
  });

  if (response?.status === 200 && response.data) {
    return response.data;
  }
};

export const onRetreiveFullAddress = async (
  id: string,
  key: string,
  country = 'aus'
) => {
  const response = await axios({
    url:
      'https://api.craftyclicks.co.uk/address/1.1/retrieve?key=' +
      key +
      '&country=' +
      country +
      '&id=' +
      id,
    method: 'GET',
    responseType: 'stream',
  });
  if (response?.status === 200 && response.data) {
    return response.data;
  }
};

/**  Checks the user's auth provider and returns the name of the service. Used in Olive's complete account forms*/
export const getAuthProviderName = (providerId: string) => {
  if (!providerId) return undefined;

  for (const item in FirebaseAuthProviderList) {
    if (
      FirebaseAuthProviderList[item as keyof typeof FirebaseAuthProviderList]
        .providerKey === providerId
    ) {
      return FirebaseAuthProviderList[
        item as keyof typeof FirebaseAuthProviderList
      ].label;
    }
  }
  return undefined;
};

export const parseId = (id: string) => {
  if (!id) return '-';
  return id.slice(0, 5).toUpperCase();
};

export const getCurrencyFormat = (
  amount: string | number,
  currency: string
) => {
  if (!amount || !currency) return '-';
  const currencyInfo = getCurrencyByCode(currency);
  return `${currencyInfo?.code} ${currencyInfo?.symbol}${Intl.NumberFormat(
    navigator.language,
    {
      style: 'currency',
      currency: currencyInfo?.code,
      currencyDisplay: 'symbol',
      maximumFractionDigits: String(amount).includes('.') ? 2 : 0,
    }
  )
    .format(Number(amount))
    .replace(/[^\d.,]/g, '')}`;
};

export const getInitialsName = (fullname: string) => {
  const names = fullname.trim().split(' '); // Split the full name into an array of individual names
  const firstNameInitial = names[0][0]; // Get the first letter of the first name
  const lastNameInitial = names[names.length - 1][0]; // Get the first letter of the last name
  const initials = firstNameInitial + lastNameInitial; // Concatenate the initials and return the result
  return initials;
};

function areAllPropertiesEmpty(obj: any) {
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== ''
    ) {
      return false;
    }
  }
  return true;
}

export const formatAddressSimple = (address: Address): string => {
  if (areAllPropertiesEmpty(address)) return '-';
  const country = address.country
    ? address.country.length <= 2
      ? getCountryByValue(address.country)?.label
      : getCountryByLabel(address.country)?.label
    : '';
  return `${address.line1}${address.line2 ? ', ' + address.line2 : ''}, ${
    address.town || address.state
  }, ${address.postcode} ${country}`;
};

export const formatAddress = (address: any, returnString = false) => {
  if (areAllPropertiesEmpty(address)) return '-';

  return returnString ? (
    `${address.address_line_1 ?? address.line1}\n` +
      `${
        address.address_line_2 ?? address.line2
          ? address.address_line_2 ?? address.line2 + '\n'
          : ''
      }` +
      `${address.city ?? address.town}\n` +
      `${address.county ? address.county + ',\n' : ''}` +
      `${address.state ? address.state + ',\n' : ''}` +
      `${address.zip_code ?? address.postcode}\n` +
      `${
        address.country
          ? address.country.length <= 2
            ? getCountryByValue(address.country)?.label
            : getCountryByLabel(address.country)?.label
          : ''
      }`
  ) : (
    <div>
      {address.address_line_1 ?? address.line1}
      <br />
      {address.address_line_2 && (
        <>
          {address.address_line_2}
          <br />
        </>
      )}
      {address.line2 && (
        <>
          {address.line2}
          <br />
        </>
      )}
      {address.city ?? address.town}
      {address.county && <>, {address.county}</>}
      {address.state && <>, {address.state}</>}
      <br />
      {address.zip_code ?? address.postcode} <br />
      {address.country && getCountryByValue(address.country)?.label}{' '}
    </div>
  );
};

export const renderFormattedAddress = (address: any, returnString = false) => {
  if (areAllPropertiesEmpty(address)) return '-';

  const formattedAddress = [
    address.address_line_1 ?? address.line1,
    address.address_line_2 ?? address.line2,
    address.city ?? address.town,
    address.county ? `${address.county},` : '',
    address.state ? `${address.state},` : '',
    address.zip_code ?? address.postcode,
    address.country
      ? address.country.length <= 2
        ? getCountryByValue(address.country)?.label
        : getCountryByLabel(address.country)?.label
      : '',
  ].filter(Boolean); // This will remove any empty strings or null values

  if (returnString) {
    return formattedAddress.join('\n'); // Join by newline character for string return
  } else {
    return (
      <div>
        {formattedAddress.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </div>
    );
  }
};

export const fixPhoneNumber = (phone: string, dialCode: string) => {
  if (phone.startsWith(dialCode)) {
    const zerosRegex = new RegExp(`^${dialCode}0+`);
    return phone.replace(zerosRegex, dialCode);
  }
  return phone;
};

export const formatPhoneNumber = (phone: string | number | undefined) => {
  if (!phone || String(phone).length === 0) {
    return '-';
  }
  // If the phone number starts with 44, it's a UK number and we need to add a (0) after the country code -VP
  const phoneStr = String(phone);

  if (phoneStr.startsWith('44')) {
    return `+44 (0) ${phoneStr.slice(2)}`;
  }
  return formatPhoneNumberIntl('+' + phoneStr);
};

export const isWarrantyExpired = (expiryDate: number) => {
  return expiryDate < Date.now() ? 'Yes' : 'No';
};

export const type = (o: any) =>
  Object.prototype.toString.call(o).toLowerCase().slice(8, -1);

export const getIncreasedLabourRate = (
  warrantyInfo: Warranty | undefined,
  currency: string
) => {
  return warrantyInfo?.appliedOptions
    ? warrantyInfo.appliedOptions
        .map((option: any) => {
          return option.key === 'increasedLabourRate'
            ? Number(option.value) || option.value === 0
              ? getCurrencyFormat(option.value, currency) + '/h'
              : option.value
            : null;
        })
        .filter(Boolean)
    : null;
};

export const getIncreasedLabourRateValue = (
  warrantyInfo: Warranty | undefined
) => {
  return warrantyInfo?.appliedOptions
    ? warrantyInfo.appliedOptions
        .map((option: any) => {
          return option.key === 'increasedLabourRate' ? option.value : null;
        })
        .filter(Boolean)[0]
    : null;
};

export const getClaimLimit = (
  warrantyInfo: Warranty | undefined,
  currency = '',
  showVat = false
) => {
  let value = warrantyInfo?.appliedOptions
    ? warrantyInfo.appliedOptions.find(
        (option: any) => option.key === 'claimLimit'
      )?.value || ''
    : '-';

  if (value === 'PPOV') return value;

  //check if value contains VAT and currency should be there if VAT is present -VP
  if (!String(value).includes('VAT')) {
    if (currency && value) {
      value =
        value.toString().length > 0 && Number(value)
          ? getCurrencyFormat(value.toString(), currency)
          : getCurrencyFormat(value?.toString().slice(1)?.toString(), currency);
    }
    if (showVat) value = `${value} + VAT`;
  }
  return value;
};

export const getWarrantyTerm = (
  holding_proxy: DTHoldingProxy | ConsumerHoldingSummaryShape
) => {
  // Get the latest warranty from the list of warranties
  const warranty = BL_Warranty.getLatestWarranty(holding_proxy?.warranties);
  let value = '-';

  if (warranty) {
    // Check if the duration is specified as an object with a label
    if (
      typeof warranty.decider?.stipulated?.duration === 'object' &&
      warranty.decider.stipulated.duration?.label
    ) {
      // If so, set the value to the label
      value = warranty.decider.stipulated.duration.label;
    } else if (
      warranty.appliedOptions?.find((option: any) => option.key === 'duration')
    ) {
      // If the duration is not specified as an object, check if it is specified as an applied option
      const durationOption = warranty.appliedOptions.find(
        (option: any) => option.key === 'duration'
      );
      // If the duration option has a value, format it using the `formatDuration` function and set the value
      value = durationOption?.value
        ? formatDuration(parse(durationOption.value))
        : '-';
    } else if (
      warranty.duration &&
      warranty.duration.amount &&
      warranty.duration.division
    ) {
      // If the duration is not specified as an object or an applied option, check if it is specified as an amount and division
      value = warranty.duration.amount + ' ' + warranty.duration.division;
    }
  }
  return value;
};

export const getAgeFromDate = (date: string) => {
  let regDateYear = new Date(date).getFullYear();
  regDateYear = Number.isNaN(regDateYear)
    ? moment(date, 'DD/MM/YYYY').year() // hotfix for WIRE
    : regDateYear;
  return new Date().getFullYear() - regDateYear;
};

export const getFormattedDate = (date?: string | number | Date | null) => {
  try {
    if (typeof date === 'number') {
      return moment(date).format('DD/MM/yyyy');
    } else if (typeof date === 'string') {
      //check if date is already with the correct format -VP
      if (typeof date === 'string' && /^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
        return date;
      }
      const fixedDate = new Date(date).toISOString();
      return moment(fixedDate).format('DD/MM/yyyy');
    } else if (date instanceof Date) {
      return moment(date).format('DD/MM/yyyy');
    } else {
      return '';
    }
  } catch (e) {
    console.warn(e);
    return '';
  }
};

export const toTitleCase = (str: string) =>
  str[0].toUpperCase() + str.slice(1).toLowerCase();

export const getUnixTimeFromDate = (date?: string | null) => {
  let timestamp =
    date && date !== '0000-00-00'
      ? moment(date, 'DD/MM/yyyy').toDate().getTime()
      : '';

  if (!timestamp && date && date !== '0000-00-00') {
    const parsedDate = moment(date, 'YYYY-MM-DD');
    timestamp = parsedDate.isValid() ? parsedDate.toDate().getTime() : '';
  }

  const maxFutureDate = moment().add(100, 'years'); // Adjust as needed
  const parsedDate = moment(timestamp);

  return parsedDate.isValid() && parsedDate.isBefore(maxFutureDate)
    ? timestamp
    : '';
};

export const getConvertedMileage = (mileage: number, unit: string) => {
  if (unit === 'mi') {
    return mileage * 1.609;
  }
  return mileage;
};

/** Renders order details for both Shopify and non-Shopify tenants
 * @param isShopifyTenant - Boolean indicating if the tenant is Shopify
 * @param data - The data containing order/purchase information
 * @param onClickHandler - Optional click handler for non-Shopify cases
 * @returns JSX Element displaying either Shopify order link or purchase proof files count
 */
export const renderOrderDetails = (
  isShopifyTenant: any,
  data: any,
  onClickHandler?: () => void
) => {
  if (isShopifyTenant) {
    const orderUrl = `https://shopify.com/${data?.shopifyLinks?.shopId}/account/orders/${data?.shopifyLinks?.orderId}`;

    return (
      <a
        className="flex cursor-pointer items-center text-black"
        href={orderUrl}
        target="_blank"
        rel="noreferrer"
      >
        View
        <div>
          <ArrowTopRightOnSquareIcon className="h-5 w-5 pl-1 text-black" />
        </div>
      </a>
    );
  }

  const totalFiles = data?.purchase_proof?.length ?? 0;
  return (
    <div
      className={
        'flex cursor-pointer items-center ' +
        (totalFiles === 0 ? 'text-red-500' : 'text-black')
      }
      onClick={onClickHandler}
    >
      {totalFiles} file(s) uploaded{' '}
      <div>
        <ArrowTopRightOnSquareIcon className="h-5 w-5 pl-1" />
      </div>
    </div>
  );
};
