import { useContext, useEffect, useMemo, useState } from 'react';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  FiltersModal,
  FilterValueShape,
  getCoverageByValue,
  getStationByKey,
  LoadingSpinner,
  Modal,
  PillTime,
  TabsGeneric,
  timestampToTimeAgo,
} from '@rabbit/elements/shared-components';
import { ClockIcon, FunnelIcon, StarIcon } from '@heroicons/react/24/outline';
import { useLocation, useNavigate } from 'react-router-dom';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { useCaseFollowing, useGetCaseProxyList } from '@rabbit/bizproc/react';
import {
  DTCaseFlowPrivateProxy,
  PersonaTypeSingleLetter,
  SELF_REGISTERED_VENDABLE,
} from '@rabbit/data/types';
import Chart from 'react-apexcharts';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import {
  formatDataChart,
  getChartOptions,
  getOperatingPersonaId,
  isStale,
  isWarrantyExpired,
  useAppInfo,
} from '../utils/helpers';
import {
  BellAlertIcon,
  StarIcon as StarFullIcon,
} from '@heroicons/react/20/solid';
import { UserContext } from '../context/UserContext';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '@rabbit/config/context';
import { ExtendedDecodedIdToken } from '@rabbit/bizproc/server-logic';
import { CreateTable, TableFields } from '../utils/CreateTable';
import { Config_ClaimDetailViewType } from '@rabbit/config/enums';

export function LiveClaimsView() {
  const location = useLocation();
  const navigate = useNavigate();
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { config } = useContext(ConfigContext);
  const operatingPersonas = config.CLAIMS.OPERATING_PERSONAS;
  const tenantLink = t('tenantLink');
  const { repairerPersona, warrantorPersona, queryStatus, installerPersona } =
    useGetMySagePersonas();
  const repairerPersonaId = repairerPersona?.personaId || '';
  const warrantorPersonaId = warrantorPersona?.personaId || '';
  const installerPersonaId = installerPersona?.personaId || '';
  const userPersonas = [
    repairerPersonaId ? PersonaTypeSingleLetter.Repairer : null,
    warrantorPersonaId ? PersonaTypeSingleLetter.Warrantor : null,
    installerPersonaId ? PersonaTypeSingleLetter.Installer : null,
  ].filter(Boolean);

  const isIWAdmin = userContext?.accessToken?.admin;

  // Manage access to the page
  const canSeeView =
    isIWAdmin ||
    config.CLAIMS.CLAIMS_LIST_TABLE.ENABLED.some((persona) =>
      userPersonas.includes(persona)
    );

  const canSeeAllClaims =
    isIWAdmin ||
    config.CLAIMS.CLAIMS_LIST_TABLE.CAN_VIEW_ALL_CLAIMS.some((persona) =>
      userPersonas.includes(persona)
    );

  if (
    !queryStatus.isLoading &&
    (!canSeeView ||
      (!canSeeAllClaims &&
        location?.pathname?.includes(SAGE_ROUTE_NAME.CLAIMS)))
  ) {
    if (installerPersona?.personaId) {
      navigate(SAGE_ROUTE_NAME.MY_CLAIMS);
    } else {
      navigate('/');
    }
  }

  let showAllCases = location?.pathname?.includes(SAGE_ROUTE_NAME.MY_CLAIMS)
    ? false
    : true;

  const tabView =
    config.CLAIMS.CLAIMS_LIST_TABLE.ASSIGNED_UNASSIGNED_TABS &&
    !location?.pathname?.includes(SAGE_ROUTE_NAME.MY_CLAIMS) &&
    warrantorPersona !== null;

  const operatingPersonaId = getOperatingPersonaId(
    operatingPersonas,
    tenantLink,
    userContext?.accessToken ?? ({} as ExtendedDecodedIdToken),
    {
      repairer: repairerPersonaId,
      warrantor: warrantorPersonaId,
      installer: installerPersonaId,
    }
  );

  const { followedCaseIds, followCase, unfollowCase } = useCaseFollowing();

  const [filters, setFilters] = useState<FilterValueShape>({
    beginDate: undefined,
    endDate: undefined,
    unassignedOnly: false,
    staleOnly: false,
    starred: false,
  });

  const {
    caseList,
    //totalCaseCount
  } = useGetCaseProxyList(
    // itemsPerPage,
    showAllCases,
    filters,
    isIWAdmin || showAllCases
      ? operatingPersonaId
      : Boolean(repairerPersonaId)
      ? repairerPersonaId
      : installerPersonaId,
    userContext?.user?.accessToken,
    operatingPersonas
  );

  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [deleteClaimOpen, setDeleteClaimOpen] = useState<boolean>(false);
  const [chartCategoriesData, setChartCategoriesData] = useState<any[] | null>(
    []
  ); //TODO check type
  const [chartIssuesTypeData, setChartIssuesTypeData] = useState<any[] | null>(
    []
  ); //TODO check type

  const countFilters = () => {
    let count = 0;
    if (filters) {
      if (filters.beginDate || filters.endDate) count += 1;

      if (filters.unassignedOnly) count += 1;
      if (filters.staleOnly) count += 1;
      if (filters.starred) count += 1;
    }
    return count;
  };

  const getWarrantyAssessment = (result: DTCaseFlowPrivateProxy) => {
    const {
      goodwill_warranty_final,
      final_assessment,
      goodwill_warranty_initial,
      preliminary_assessment,
    } = result.facts || {};

    const current_assessment =
      goodwill_warranty_final ||
      final_assessment ||
      goodwill_warranty_initial ||
      preliminary_assessment;

    return current_assessment && current_assessment.trim() !== ''
      ? getCoverageByValue(current_assessment)?.label
      : '-';
  };

  const setupChartCategories = () => {
    const categories: { id: any; count: number; label: any }[] = [];
    const claimsList = caseList?.data;

    if (!claimsList?.length) {
      setChartCategoriesData(null);
      return;
    }

    const MISSING_CATEGORY_ID = '1';

    claimsList.forEach((claim, i) => {
      if (
        claim.facts.consumer_holding_category &&
        claim.facts.consumer_holding_category !== 'n/a' &&
        claim.facts.consumer_holding_category !== '-'
      ) {
        const existingItem = categories.find(
          (item) => item.id === claim.facts.consumer_holding_category
        );
        if (existingItem) {
          existingItem.count++;
        } else {
          categories.push({
            id: claim.facts.consumer_holding_category,
            count: 1,
            label:
              claim.facts.consumer_holding_category === '-'
                ? claim.facts.consumer_holding_category
                : claim.facts.consumer_holding_category
                    .replace(/-/g, ' ')
                    .replace(/\b\w/g, (char: string) => char.toUpperCase()), //replace dashes for empty spaces and transform to uppercase
          });
        }
      } else {
        const existingItem = categories.find(
          (item) => item.id === MISSING_CATEGORY_ID
        );
        if (existingItem) {
          existingItem.count++;
        } else {
          categories.push({
            id: MISSING_CATEGORY_ID,
            count: 1,
            label: 'Other',
          });
        }
      }
    });
    const sortedCategories = categories.sort((a, b) => b.count - a.count);

    setChartCategoriesData(
      sortedCategories.length
        ? formatDataChart(sortedCategories, 'series', 'Claims', 5)
        : null
    );
  };

  const setupChartFaultIssueType = () => {
    const issuesType: { id: any; count: number; label: any }[] = [];
    const claimsList = caseList?.data;

    if (!claimsList?.length) {
      setChartIssuesTypeData(null);
      return;
    }

    claimsList.forEach((claim, i) => {
      const existingItem = issuesType.find(
        (item) => item.label === claim.facts.consumer_issue_type
      );
      if (existingItem) {
        existingItem.count++;
      } else {
        issuesType.push({
          id: claim.facts.consumer_issue_type_ref,
          count: 1,
          label: claim.facts.consumer_issue_type ?? 'No title',
        });
      }
    });

    const sortedIssuesType = issuesType.sort((a, b) => b.count - a.count);
    setChartIssuesTypeData(
      formatDataChart(sortedIssuesType, 'series', 'Claims', 5)
    );
  };

  const onChangeFilters = (value: FilterValueShape) => {
    setIsFilterModalOpen(false);
    setFilters(value);
  };

  const modalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: t(
        'You are about to delete this claim. You will lose all of the information. Are you sure you want to continue?'
      ),
      primaryButtonText: 'Yes',
      outlineButtonText: 'No, cancel',
      handleClose: () => setDeleteClaimOpen(false),
      handlePrimaryClick: async () =>
        console.log('delete --> selectedRowToDelete'),
      handleOutlineClick: () => setDeleteClaimOpen(false),
    },
  };

  const getProductName = (result: DTCaseFlowPrivateProxy) => {
    if (result?.facts?.holding_vendable_id === SELF_REGISTERED_VENDABLE) {
      return (
        result?.facts?.srvProductInfo?.brand +
        ' ' +
        result?.facts?.srvProductInfo?.title
      );
    } else return result?.facts?.consumer_holding_name ?? '-';
  };

  const createTableFields = (type: 'default' | 'assigned' | 'unassigned') =>
    (
      [
        {
          header: 'spotlight',
          Header: <BellAlertIcon className="h-4 w-4" />,
          Cell: ({ cell }: any) => {
            return (
              cell.getValue() && (
                <div className="flex items-center px-2">
                  <div className="h-[10px] w-[10px] rounded-full bg-red-500"></div>
                </div>
              )
            );
          },
          value: (data) =>
            (operatingPersonaId &&
              data?.case_spotlight?.[0] === operatingPersonaId) ||
            data?.actor_chat_unread
              ? true
              : false,
        },
        {
          header: 'timestamp',
          Header: <ClockIcon className="h-4 w-4" />,
          Cell: ({ cell }: any) => {
            return (
              <PillTime
                label={
                  cell.getValue() ? timestampToTimeAgo(cell.getValue()) : ''
                }
                className={'bg-gray-300 text-gray-900'}
              />
            );
          },
          value: (data) => data.tcreate,
        },
        {
          header: t('general.inWarranty'),
          type: 'Status',
          value: (data) =>
            isWarrantyExpired(
              data.facts.purchase_date,
              data.facts.holding_warranty_term
            ),
        },
        {
          header: t('general.customerName'),
          value: (data) => data.facts.consumer_name || '-',
        },
        type === 'assigned'
          ? {
              header: t('general.assignedTo'),
              value: (data) => data.facts?.delegate_repairer_name || '-',
            }
          : undefined,
        {
          header: t('general.claimsStatus'),
          value: (data) => getStationByKey(data.case_state, t).label ?? '-',
        },
        config.CLAIMS.CLAIM_DETAIL_VIEW.TYPE ===
        Config_ClaimDetailViewType.CAR_DETAILS
          ? {
              header: t('general.registrationNo'),
              value: (data) =>
                data?.facts?.srvProductInfo?.srvInfo?.productInfo
                  ?.registrationNo,
            }
          : undefined,
        {
          header: t('general.product'),
          value: (data) => getProductName(data),
          primary: true,
        },
        config.INSTALLATION_REQUIRED
          ? {
              header: t('general.installer'),
              value: (data) =>
                data.facts.delegate_installer_name ?? 'Unassigned',
            }
          : undefined,
        {
          header: t('general.reportedFault'),
          value: (data) => data.facts?.consumer_issue_type ?? '-',
        },
        {
          header: t('general.assessment'),
          value: (data) => getWarrantyAssessment(data),
        },
        {
          header: t('general.outcome'),
          value: (data) => data.facts?.claim_outcome ?? '-',
        },

        {
          header: '',
          enableColumnActions: false,
          Cell: ({ cell }: any) => {
            const value = cell.getValue();
            const { isStarred, id } = value || {};
            return (
              <div className="flex items-center justify-center">
                {isStarred ? (
                  <StarFullIcon
                    className="h-5 w-5 cursor-pointer"
                    onClick={async () => {
                      await unfollowCase(id);
                    }}
                  />
                ) : (
                  <StarIcon
                    className="h-5 w-5 cursor-pointer"
                    onClick={async () => {
                      await followCase(id);
                    }}
                  />
                )}
              </div>
            );
          },
          value: (data) =>
            data?.docid && followedCaseIds.includes(data?.docid)
              ? { isStarred: true, id: data.docid }
              : { isStarred: false, id: data.docid },
        },
      ] as TableFields<DTCaseFlowPrivateProxy>
    ).filter((i) => i !== undefined);

  const dataList = queryStatus.isLoading ? [] : caseList?.data ?? [];
  const createTable = (type: 'default' | 'unassigned' | 'assigned') =>
    CreateTable<DTCaseFlowPrivateProxy>(
      dataList
        .filter((result: DTCaseFlowPrivateProxy) => {
          if (filters.staleOnly) {
            return (
              isStale(result.tupdate, appInfo) && result.case_state !== 'closed'
            );
          }
          return true;
        })
        .filter((result: DTCaseFlowPrivateProxy) => {
          if (filters.starred && followedCaseIds.length) {
            return result?.docid && followedCaseIds.includes(result?.docid);
          }
          return true;
        })
        .filter((result: DTCaseFlowPrivateProxy) => {
          if (type === 'unassigned')
            return result.facts.delegate_repairer_id === 'Unassigned';
          if (type === 'assigned')
            return result.facts.delegate_repairer_id !== 'Unassigned';
          return true;
        }),
      createTableFields(type)
    );

  const DefaultClaimsTable = useMemo(
    () => createTable('default'),
    [
      caseList?.data,
      caseList?.isSuccess,
      filters,
      followedCaseIds.length,
      followCase,
      unfollowCase,
    ]
  );

  const UnassignedTable = useMemo(
    () => createTable('unassigned'),
    [
      caseList?.data,
      caseList?.isSuccess,
      filters,
      followedCaseIds.length,
      followCase,
      unfollowCase,
    ]
  );

  const AssignedTable = useMemo(
    () => createTable('assigned'),
    [
      caseList?.data,
      caseList?.isSuccess,
      filters,
      followedCaseIds.length,
      followCase,
      unfollowCase,
    ]
  );

  useEffect(() => {
    if (!queryStatus.isLoading) {
      setupChartFaultIssueType();
      setupChartCategories();
    }
  }, [
    caseList?.data,
    caseList?.isSuccess,
    filters,
    followedCaseIds.length,
    queryStatus.isLoading,
  ]);

  useEffect(() => {
    setFilters({
      beginDate: undefined,
      endDate: undefined,
      unassignedOnly: false,
      staleOnly: false,
      starred: false,
    });
  }, [showAllCases]);

  const tabs = [
    {
      label: t('general.unassignedClaims'),
      children: (
        <UnassignedTable
          muiTableBodyRowProps={({ row }: any) => ({
            className: row.original.stale
              ? '!bg-gray-50 !hover:bg-gray-50'
              : 'relative z-0 hover:z-10',
          })}
          initialState={{
            columnPinning: { right: ['actions'] },
            sorting: [{ id: 'timestamp', desc: true }],
            showGlobalFilter: true,
            //pagination: { pageSize: 5, pageIndex: 1 }
          }}
          muiSearchTextFieldProps={{
            placeholder: t('message.searchByWarrantyIdOrProductName'),
          }}
          onClickRow={(data) => {
            navigate(SAGE_ROUTE_NAME.CLAIMS + '/' + data.docid, {
              state: {
                docid: data.docid,
                link: data.docid,
                productName: getProductName(data),
                extraData: {
                  modelNumber: data.facts?.consumer_holding_serial_number,
                },
              },
            });
          }}
          state={{
            isLoading: caseList.isLoading,
          }}
        />
      ),
    },
    {
      label: t('general.assignedClaims'),
      children: (
        <AssignedTable
          muiTableBodyRowProps={({ row }: any) => ({
            className: row.original.stale
              ? '!bg-gray-50 !hover:bg-gray-50'
              : 'relative z-0 hover:z-10',
          })}
          initialState={{
            columnPinning: { right: ['actions'] },
            sorting: [{ id: 'timestamp', desc: true }],
            showGlobalFilter: true,
            //pagination: { pageSize: 5, pageIndex: 1 }
          }}
          muiSearchTextFieldProps={{
            placeholder: t('message.searchByWarrantyIdOrProductName'),
          }}
          onClickRow={(data) => {
            navigate(SAGE_ROUTE_NAME.CLAIMS + '/' + data.docid, {
              state: {
                docid: data.docid,
                link: data.docid,
                productName: getProductName(data),
                extraData: {
                  modelNumber: data.facts?.consumer_holding_serial_number,
                },
              },
            });
          }}
          state={{
            isLoading: caseList.isLoading,
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="mb-5 grid grid-cols-2 gap-5">
        <CardWrapperWithHeader title={t('message.categories')}>
          {chartCategoriesData?.length ? (
            <Chart
              options={getChartOptions(chartCategoriesData)}
              series={chartCategoriesData}
              type="bar"
              height={'250px'}
            />
          ) : chartCategoriesData === null ? (
            t('message.noDataAvailableToDisplay')
          ) : (
            <LoadingSpinner size="xs" />
          )}
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title={t('general.reportedFaults')}>
          {chartIssuesTypeData?.length ? (
            <Chart
              options={getChartOptions(chartIssuesTypeData)}
              series={chartIssuesTypeData}
              type="bar"
              height={'250px'}
            />
          ) : chartIssuesTypeData === null ? (
            t('message.noDataAvailableToDisplay')
          ) : (
            <LoadingSpinner size="xs" />
          )}
        </CardWrapperWithHeader>
      </div>
      <div className="relative z-10 flex w-full items-center justify-between py-4">
        <div
          className={`absolute ${
            tabView ? 'top-[150px]' : 'top-[60px]'
          } right-5`}
        >
          <ButtonIcon
            label={t('general.filters')}
            iconLeft={true}
            Icon={FunnelIcon}
            onClick={() => setIsFilterModalOpen(true)}
            kind={'bgLightGreen'}
            count={countFilters()}
          />
        </div>
        {isFilterModalOpen && (
          <div className="absolute right-0 top-28 z-10">
            <FiltersModal
              page={
                location?.pathname?.includes(SAGE_ROUTE_NAME.MY_CLAIMS)
                  ? 'my-claims'
                  : 'claims'
              }
              handleClose={() => setIsFilterModalOpen(false)}
              data={filters}
              onChange={onChangeFilters}
            />
          </div>
        )}
      </div>
      {tabView ? (
        <div className="pt-5 pb-4">
          <TabsGeneric items={tabs} />
        </div>
      ) : (
        <DefaultClaimsTable
          muiTableBodyRowProps={({ row }: any) => ({
            className: row.original.stale
              ? '!bg-gray-50 !hover:bg-gray-50'
              : 'relative z-0 hover:z-10',
          })}
          initialState={{
            columnPinning: { right: ['actions'] },
            sorting: [{ id: 'timestamp', desc: true }],
            showGlobalFilter: true,
            //pagination: { pageSize: 5, pageIndex: 1 }
          }}
          muiSearchTextFieldProps={{
            placeholder: t('message.searchByWarrantyIdOrProductName'),
          }}
          onClickRow={(data) => {
            navigate(SAGE_ROUTE_NAME.CLAIMS + '/' + data.docid, {
              state: {
                docid: data.docid,
                link: data.docid,
                productName: getProductName(data),
                extraData: {
                  modelNumber: data.facts?.consumer_holding_serial_number,
                },
              },
            });
          }}
          state={{
            isLoading: caseList.isLoading,
          }}
        />
      )}
      {deleteClaimOpen && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={modalSettings.kind}
          settings={modalSettings.settings}
        />
      )}
    </>
  );
}

export default LiveClaimsView;
