import {
  ArrowsPointingOutIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/solid';
import { AppInfoShape, Warranty, WarrantyStatus } from '@rabbit/data/types';
import { Button, Modal } from '@rabbit/elements/shared-components';
import { HtmlHTMLAttributes, useState } from 'react';
import WarrantyDetailSection from './WarrantyDetailSection';
import { useTranslation } from 'react-i18next';
import { TenantType } from './WarrantyTypes';

export function WarrantyViewer({
  warranties,
  appInfo,
  tenantType = 'defaultTenants',
  className,
}: {
  tenantType: TenantType;
  appInfo: AppInfoShape;
  warranties: Warranty[];
} & HtmlHTMLAttributes<HTMLDivElement>) {
  const [showWarrantyModal, setShowWarrantyModal] = useState(false);
  const { t } = useTranslation();

  const actives = warranties.filter((w) => w.status === WarrantyStatus.ACTIVE);
  const expired = warranties.filter((w) => w.status === WarrantyStatus.EXPIRED);
  const voided = warranties.filter((w) => w.status === WarrantyStatus.VOIDED);

  let summary = '';
  if (actives.length > 0) summary += `${actives.length} active, `;
  if (expired.length > 0) summary += `${expired.length} expired, `;
  if (voided.length > 0) summary += `${voided.length} voided, `;
  if (summary.length > 0) summary = summary.slice(0, -2);

  return warranties.length === 1 ? (
    <WarrantyDetailSection
      warranty={warranties[0]}
      tenantType={tenantType}
      appInfo={appInfo}
    />
  ) : (
    <>
      <div className={` ${className}`}>
        <div className="flex items-center justify-between rounded-md bg-white py-3 px-4">
          <div className="flex items-center gap-4 ">
            <ShieldCheckIcon className="text-primary-600 h-8 w-8" />
            <div className="font-nunito flex flex-col">
              <span className="text-lg font-bold">
                {tenantType === 'tableTenants'
                  ? t('general.servicePlanInformation')
                  : t('general.warrantyInformation')}
              </span>
              <span className=" -mt-[2px] text-gray-500">{summary}</span>
            </div>
          </div>
          <Button
            kind={'outline'}
            className={
              'hover:bg-primary-800 w-fit grow-0 gap-2 hover:text-white'
            }
            onClick={() => setShowWarrantyModal(true)}
          >
            <ArrowsPointingOutIcon className="h-4 w-4" />
            Expand
          </Button>
        </div>
      </div>

      {showWarrantyModal && (
        <Modal
          kind="generic"
          settings={{ handleClose: () => setShowWarrantyModal(false) }}
          className="m-auto w-full rounded-md border bg-white md:w-[1000px]"
        >
          <div className="font-nunito flex flex-col p-4">
            <span className="font-caudex text-lg font-bold">
              {tenantType === 'tableTenants'
                ? t('general.servicePlanInformation')
                : t('general.warrantyInformation')}
            </span>
            <span className=" -mt-[2px] text-gray-500">{summary}</span>
          </div>
          <div className="flex flex-col gap-4 bg-gray-100 p-4">
            {warranties
              .sort((w1) => (w1.status === WarrantyStatus.ACTIVE ? -1 : 1))
              .map((warranty) => (
                <WarrantyDetailSection
                  appInfo={appInfo}
                  warranty={warranty}
                  tenantType={tenantType}
                />
              ))}
          </div>
          <div className="px-4 pt-4">
            <Button
              kind="primary"
              className=""
              onClick={() => setShowWarrantyModal(false)}
            >
              {t('general.okay')}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default WarrantyViewer;
