import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, ButtonIcon, Input } from '@rabbit/elements/shared-components';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import {
  LIST_COUNTRIES,
  OptionShape,
  useSageAPI,
  FileStorageContext,
  getCompletedUploadsOfCategory,
} from '@rabbit/bizproc/react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { NewClaimModalSteps } from '../../ModalNewClaim/ModalNewClaim';
import { OurDateTime } from '@rabbit/utils/ts';
import { useAppInfo } from '@rabbit/sage/utils/helpers';
import { ConfigContext } from '@rabbit/config/context';
import { SageFileUploader } from '../../upload-wrapper/SageFileUploader';
import {
  CompletedUploadShape,
  DocTypeShapeTypes,
  FileUploaderVariations,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import {
  FBD_Vendable,
  UserUploadedDocument,
  FBD_Holding_Private,
  FBD_Consumer_Private,
} from '@rabbit/data/types';
import { PencilIcon } from '@heroicons/react/24/solid';
import { useGetInstallerPeers } from '@rabbit/bizproc/react';
import { useGetMySagePersonas } from '@rabbit/data/portal';

export interface NewCreateHoldingFormProps {
  onHoldingCreated: (result: any) => void;
  handleClose: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onChange: (step: NewClaimModalSteps, data?: any) => void;
  data: any;
}

export interface HoldingDataCase {
  holding_vendable_id: string;
  purchase_time: Date | null;
  purchase_price: {
    amount: number;
    currency: string;
  };
  purchase_location: {
    docid: string;
    country: string;
  } | null;
  store_not_listed: boolean;
  custom_store_name: string;
  purchase_country: string;
  serial_number: string;
  serial_number_proof: UserUploadedDocument[];
  installation_attachment: UserUploadedDocument[];
  installer_id: string;
  purchase_proof: UserUploadedDocument[];
}

export function NewCreateHoldingForm(props: NewCreateHoldingFormProps) {
  const { config } = useContext(ConfigContext) || {};
  const { registerConsumerHoldingSage } = useSageAPI();
  const { handleClose, onChange, data, onHoldingCreated, setLoading } = props;
  const defaultSelected = data?.productResponse || null;
  const [previousData, setPreviousData] = useState(data?.previewData || {});

  const formikRef = useRef(null) as any;
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDatePurchaseDisabled, setIsDatePurchaseDisabled] = useState(false);
  const { installerPersona } = useGetMySagePersonas();
  const [isValidateOnChange, setIsValidateOnChange] = useState(false);

  const serialNumber = window.localStorage.getItem('LRP_VendableSn') ?? '';
  const initialValues: HoldingDataCase = {
    holding_vendable_id: previousData?.values?.holding_vendable_id || '',
    purchase_time: previousData?.values?.purchase_time
      ? new Date(previousData?.values?.purchase_time)
      : null,
    purchase_price: {
      amount: previousData?.values?.purchase_price?.amount || 0,
      currency:
        previousData?.values?.purchase_price?.currency || appInfo.currency,
    },
    purchase_location: previousData?.location?.value
      ? { ...previousData?.location?.value }
      : null,
    store_not_listed: previousData?.values?.store_not_listed || false,
    custom_store_name: previousData?.values?.custom_store_name || '',
    purchase_country: previousData?.values?.purchase_country || appInfo.country,
    serial_number: (previousData?.values?.serial_number || serialNumber) ?? '',
    serial_number_proof: previousData?.values?.serial_number_proof || [],
    installation_attachment: [],
    installer_id: '',
    purchase_proof: previousData?.values?.purchase_proof || [],
  };

  const validationSchema = Yup.object().shape({
    holding_vendable_id: Yup.string()
      .trim()
      .required(t('general.productNameCantBeEmpty')),
    purchase_location: Yup.mixed(),
    store_not_listed: Yup.boolean(),
    purchase_price: Yup.object({
      amount: Yup.number()
        .min(1, t('message.pleaseEnterValidAmount'))
        .required(t('message.pleaseEnterValidAmount')),
      currency: Yup.string().trim().required(t('message.currencyIsRequired')),
    })
      .required(t('message.pleaseEnterPurchasePrice'))
      .typeError(t('message.pleaseEnterPurchasePrice')),
    purchase_time: Yup.string().nullable().trim(),
    ...(config?.CLAIMS?.CLAIMS_FLOW.INSTALLATION && {
      installation_date: Yup.date().required(
        t('message.installationDateRequired')
      ),
      installation_time: Yup.string()
        .trim()
        .required(t('message.installationTimeRequired')),
    }),
    ...(config?.CLAIMS?.CLAIMS_FLOW.INSTALLATION &&
      !installerPersona?.personaId && {
        installer_id: Yup.string(),
      }),
    ...(config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER && {
      serial_number: Yup.string().trim(),
    }),
    ...(config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF && {
      serial_number_proof: Yup.array(),
    }),
    ...(config?.HOLDINGS.NEW_REGISTRATION_FLOW.PROOF_OF_PURCHASE && {
      purchase_proof: Yup.array(),
    }),
    // purchase_time: Yup.date()
    //   .max(new Date(), 'Please enter a valid date')
    //   .required('Please select a purchase time')
    //   .typeError('Please enter a valid date'),
  });

  const {
    uploadQueueState,
    moveCompletedUploadsToAttached,
    isUpdating,
    updateHoldingWithFiles,
  } = useContext(FileStorageContext) || {};
  const { completed } = uploadQueueState ?? {};
  const serialNumberProofUploads: CompletedUploadShape[] =
    getCompletedUploadsOfCategory(
      completed ?? [],
      UploadedFileCategories.SerialNumberProof
    );

  const installerAttachmentUploads: CompletedUploadShape[] =
    getCompletedUploadsOfCategory(
      completed ?? [],
      UploadedFileCategories.installation_attachment
    );

  const proofOfPurchaseUploads: CompletedUploadShape[] =
    getCompletedUploadsOfCategory(
      completed ?? [],
      UploadedFileCategories.ConsumerProofPurchase
    );

  // generate holdingLink using Keygen here so the uploader of serial number proof can work
  const holding_private = FBD_Holding_Private.empty();
  const holding_docid =
    config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF ||
    config.INSTALLATION_REQUIRED
      ? FBD_Holding_Private.keygen(holding_private, FBD_Holding_Private)
      : '';

  const consumer_private = FBD_Consumer_Private.empty();
  const consumer_docid = config?.CLAIMS?.CLAIMS_FLOW?.SERIAL_NUMBER_PROOF
    ? FBD_Consumer_Private.keygen(consumer_private, FBD_Consumer_Private)
    : '';

  const newConsumerLink = data?.consumer?.id ?? consumer_docid;

  const onSubmit = async (values: HoldingDataCase) => {
    const serialNumberProofFiles = serialNumberProofUploads?.flatMap((file) =>
      file.category === UploadedFileCategories.SerialNumberProof &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );

    const proofOfPurchaseFile = proofOfPurchaseUploads?.flatMap((file) =>
      file.category === UploadedFileCategories.ConsumerProofPurchase &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );
    if (config.HOLDINGS.NEW_REGISTRATION_FLOW.SUBMISSION_PREVIEW) {
      onChange('submission-preview', {
        ...data,
        previewData: {
          ...data?.previewData,
          ...previousData,
          values,
          serialNumberProofFiles,
          proofOfPurchaseFile,
          holding_docid,
        },
      });
    } else {
      createNewHolding(values);
    }
  };

  const createNewHolding = async (values: HoldingDataCase) => {
    setIsSubmitting(true);
    setLoading(true);

    // If values include a retailer from the database, then purchase_location_other should be cleared - and vice versa
    const newPurchaseLocation =
      values.custom_store_name && values.store_not_listed
        ? null
        : values.purchase_location?.docid ?? '';

    const newPurchaseLocationOther = values.store_not_listed
      ? values.custom_store_name ?? ''
      : null;
    const newPurchaseCountry =
      values.custom_store_name && values.store_not_listed
        ? values.purchase_country
        : values.purchase_location?.country ?? '';

    const serialNumberProofFiles = serialNumberProofUploads?.flatMap((file) =>
      file.category === UploadedFileCategories.SerialNumberProof &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );

    const proofOfPurchaseFile = proofOfPurchaseUploads?.flatMap((file) =>
      file.category === UploadedFileCategories.ConsumerProofPurchase &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );

    const installationAttachmentFiles = installerAttachmentUploads?.flatMap(
      (file) =>
        file.category === UploadedFileCategories.installation_attachment &&
        file?.uploadedFile
          ? [file.uploadedFile]
          : []
    );
    // todo: make/update type from this
    const finalData = data.holding
      ? {
          consumer_first_name: data.consumer.splitname?.first,
          consumer_last_name: data.consumer.splitname?.last,
          consumer_address: data.consumer.address,
          consumer_email: data.consumer.email,
          consumer_telephone: data.consumer.phone,
          consumer_preferred_contact: data.consumer.preferred_contact,
          holding_vendable_id: data.holding.vendable,
          purchase_location: newPurchaseLocation,
          purchase_location_other: newPurchaseLocationOther,
          purchase_price: {
            amount: Number(values.purchase_price.amount),
            currency: values.purchase_price.currency,
          },
          purchase_time: values.purchase_time
            ? OurDateTime.dateToTimestamp(values.purchase_time)
            : 0,
          purchase_country: values.purchase_location
            ? values.purchase_location.country
            : values.purchase_country,
          consumer_holding_purchase_location: newPurchaseLocation,
          consumer_holding_purchase_country: newPurchaseCountry,
          consumer_holding_purchase_location_other: newPurchaseLocationOther,
          purchase_date: data.holding.purchase_time,
          consumer_is_commercial: data.consumer_is_commercial,
          serial: data.serial ?? '',
          installation_date: data.installation_date ?? '',
          installation_time: data.installation_time ?? '',
          installer_id:
            installerPersona?.personaId ?? values.installer_id ?? '',
          serial_number_proof: serialNumberProofFiles ?? [],
          installation_attachment: installationAttachmentFiles ?? [],
          internal_comment: data.internal_comment ?? '',
          purchase_proof: proofOfPurchaseFile ?? [],
        }
      : ({
          ...data,
          ...values,
          purchase_date: values.purchase_time
            ? OurDateTime.dateToTimestamp(values.purchase_time)
            : null,
          consumer_holding_purchase_location: newPurchaseLocation,
          consumer_holding_purchase_country: newPurchaseCountry,
          consumer_holding_purchase_location_other: newPurchaseLocationOther,
          serial: values.serial_number ?? '',
          installer_id:
            installerPersona?.personaId ?? values.installer_id ?? '',
          serial_number_proof: serialNumberProofFiles ?? [],
          installation_attachment: installationAttachmentFiles ?? [],
          purchase_proof: proofOfPurchaseFile ?? [],
        } as any);

    try {
      const response = await registerConsumerHoldingSage({
        formData: finalData,
        tenantLink: t('tenantLink'),
        newHoldingLink: holding_docid,
        newConsumerLink: newConsumerLink,
      });

      if (response) {
        if (config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF) {
          if (
            moveCompletedUploadsToAttached &&
            updateHoldingWithFiles &&
            serialNumberProofFiles &&
            serialNumberProofFiles.length > 0
          ) {
            await updateHoldingWithFiles(
              holding_docid,
              serialNumberProofFiles as UserUploadedDocument[],
              UploadedFileCategories.SerialNumberProof
            );
            moveCompletedUploadsToAttached(serialNumberProofUploads);
          }
        }

        if (config?.INSTALLATION_REQUIRED) {
          if (
            moveCompletedUploadsToAttached &&
            updateHoldingWithFiles &&
            installationAttachmentFiles &&
            installationAttachmentFiles.length > 0
          ) {
            await updateHoldingWithFiles(
              holding_docid,
              installationAttachmentFiles as UserUploadedDocument[],
              UploadedFileCategories.installation_attachment
            );
            moveCompletedUploadsToAttached(installerAttachmentUploads);
          }
        }

        if (config?.HOLDINGS.NEW_REGISTRATION_FLOW.PROOF_OF_PURCHASE) {
          if (
            moveCompletedUploadsToAttached &&
            updateHoldingWithFiles &&
            proofOfPurchaseFile &&
            proofOfPurchaseFile.length > 0
          ) {
            await updateHoldingWithFiles(
              holding_docid,
              proofOfPurchaseFile as UserUploadedDocument[],
              UploadedFileCategories.ConsumerProofPurchase
            );
            moveCompletedUploadsToAttached(proofOfPurchaseUploads);
          }
        }

        onHoldingCreated({
          consumerLink: response.consumerLink,
          holdingLink: response.holdingLink,
          warranty: response.warranty,
        });

        handleClose();
      } else {
        throw new Error('general.failedToRegisterHolding');
      }
    } catch (err: any) {
      console.log(err);
      toast.error(
        err?.message ?? t('general.somethingWentWrongPleaseTryAgain')
      );
      setIsSubmitting(false);
      setLoading(false);
    }
  };

  const { installerPeer, installerPeerLoading } = useGetInstallerPeers();
  const installerUsers: OptionShape[] = installerPeerLoading
    ? [
        {
          id: '-1',
          label: 'Loading...',
          value: 'Loading...',
        },
      ]
    : installerPeer.map((installer, index) => ({
        id: `${index}`,
        label: installer?.name || '',
        value: installer?.docid || '',
      }));

  useEffect(() => {
    if (formikRef && formikRef.current && isDatePurchaseDisabled) {
      formikRef.current.setFieldValue('purchase_time', null);
    }
  }, [isDatePurchaseDisabled]);

  function displayAddNewProduct() {
    return (
      <Button
        kind="primary"
        className="w-full"
        onClick={() => onChange('add-new-product', null)}
      >
        {t('general.addNewProduct')}
      </Button>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={isValidateOnChange}
      validateOnBlur={false}
      innerRef={formikRef}
    >
      {({ errors, values, setFieldValue, validateForm, handleSubmit }) => (
        <Form
          className="flex-col gap-3 px-5"
          onSubmit={(e) => {
            e.preventDefault();
            validateForm();
            setIsValidateOnChange(true);
            handleSubmit(e);
          }}
        >
          <div className="mt-4 grid grid-cols-2 gap-4">
            {!data.holding && (
              <>
                <div className={`relative h-auto w-full`}>
                  <Input
                    type="autocomplete-vendable"
                    name="holding_vendable_id"
                    label={`${t('general.product')}*`}
                    settings={{
                      isMulti: false,
                      id: 'holding_vendable_id',
                      placeholder: t('general.productName'),
                      options: [],
                      errors: errors,
                      hint: t('*required'),
                      tenantLink: t('tenantLink'),
                      customButton: config.HOLDINGS.NEW_REGISTRATION_FLOW
                        .ADD_NEW_PRODUCT
                        ? displayAddNewProduct()
                        : null,
                      disabled: !!defaultSelected,
                      value: defaultSelected,
                    }}
                    onChange={(items) => {
                      setPreviousData((pre: any) => ({
                        ...pre,
                        product: items,
                      }));
                    }}
                  />
                  {defaultSelected?.id &&
                    config.HOLDINGS.NEW_REGISTRATION_FLOW.UNIQUE_PRODUCT && (
                      <ButtonIcon
                        label={''}
                        className={`absolute right-4 top-9`}
                        Icon={PencilIcon}
                        iconLeft
                        onClick={() => {
                          onChange('add-new-product', {
                            ...data,
                            editUniqueProduct: defaultSelected.id,
                          });
                        }}
                      />
                    )}
                </div>
                <div className="w-full flex-col">
                  <Input
                    type="datepicker"
                    name="purchase_time"
                    label={t('general.purchaseDate')}
                    settings={{
                      id: 'purchase_time',
                      placeholder: t('general.purchaseDate'),
                      maxDate: new Date(),
                      disabled: isDatePurchaseDisabled,
                    }}
                  />
                  <div className="flex items-center pt-2">
                    <Input
                      type="checkbox"
                      name="noDateOfPurchase"
                      settings={{
                        checkboxLabel: t('general.dateNotKnown'),
                        checkboxLabelStyles: 'text-base text-gray-500',
                        onChange: () =>
                          setIsDatePurchaseDisabled(!isDatePurchaseDisabled),
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className={
              installerPersona?.personaId
                ? 'mt-4 grid grid-cols-2 gap-4'
                : 'mt-4 grid grid-cols-3 gap-4'
            }
          >
            {!data.holding && config.INSTALLATION_REQUIRED && (
              <>
                <div className="w-full flex-col">
                  <Input
                    type="datepicker"
                    name="installation_date"
                    label={`${t('general.installationDate')}*`}
                    settings={{
                      id: 'installation_date',
                      placeholder: 'DD/MM/YYYY',
                      minDate: new Date(),
                      initialDate: new Date(),
                      hint: '*required',
                    }}
                  />
                </div>
                <div className="w-full flex-col">
                  <Input
                    type="time"
                    name="installation_time"
                    label={`${t('general.installationTime')}*`}
                    settings={{
                      id: 'installation_time',
                      placeholder: t('general.installationTime'),
                      hint: '*required',
                      format: 'hh:mm',
                    }}
                  />
                </div>
                {!installerPersona?.personaId && (
                  <Input
                    type="select"
                    label="Assign Installer"
                    name="installer_id"
                    settings={{
                      options: installerUsers ?? [],
                      id: 'installer_id',
                      placeholder: 'Please select an Installer',
                      hint: '*required',
                    }}
                  />
                )}
              </>
            )}
          </div>
          <div
            className={`mt-4 grid gap-4 ${
              data.holding ? 'grid-cols-1' : 'grid-cols-2'
            } `}
          >
            <Input
              type="currency"
              name="purchase_price"
              label={`${t('general.purchasePrice')}*`}
              settings={{
                id: 'purchase_price',
                placeholder: t('general.purchaseAmount'),
                currency: appInfo.currency,
                hint: '*required',
              }}
            />
            {!data.holding && (
              <div className="flex grow flex-col gap-2">
                <Input
                  type="autocomplete-location-retailer"
                  name="purchase_location"
                  label={t('general.store')}
                  settings={{
                    id: 'purchase_location',
                    isMulti: false,
                    placeholder: t('message.pleaseSelectAnOption'),
                    options: previousData?.location
                      ? [previousData?.location]
                      : [],
                    errors: errors,
                    disabled: values.store_not_listed,
                  }}
                  onChange={(item) => {
                    console.log('item', item);
                    setPreviousData((pre: any) => ({
                      ...pre,
                      location: item,
                    }));
                  }}
                />
                <Input
                  type="checkbox"
                  name="store_not_listed"
                  settings={{
                    checkboxLabel: t('general.storeIsNotListed'),
                    checkboxLabelStyles: 'text-base text-gray-500',
                  }}
                />
                {values.store_not_listed && (
                  <div className="mt-2 flex flex-col gap-2">
                    <Input
                      type="text"
                      name="custom_store_name"
                      settings={{
                        id: 'custom_store_name',
                        placeholder: t('general.enterStoreName'),
                        allowSpecialCharacter: true,
                        allowedSpecialCharacters: [`'`],
                      }}
                    />
                    <Input
                      type="select"
                      label=""
                      name="purchase_country"
                      settings={{
                        options: LIST_COUNTRIES,
                        id: 'purchase_country',
                        placeholder: t(
                          'general.countryWhereProductWasPurchased'
                        ),
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {config?.HOLDINGS.NEW_REGISTRATION_FLOW.PROOF_OF_PURCHASE && (
            <div className="my-4 flex flex-col gap-2">
              <SageFileUploader
                label={t('general.proofOfPurchase')}
                identifiers={{
                  category: UploadedFileCategories.ConsumerProofPurchase,
                  docType: {
                    docid: holding_docid,
                    type: DocTypeShapeTypes.Holding,
                  },
                  personaId: newConsumerLink,
                }}
                maxFiles={1}
                onUploadCompleted={(files) =>
                  setFieldValue('purchase_proof', files)
                }
                onDeleteFile={() => setFieldValue('purchase_proof', [])}
                currentFiles={values.purchase_proof ?? []}
                shouldAutoUpdateDocs={false}
                accepts={['image/*', '.pdf']}
              />
            </div>
          )}
          {config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER && (
            <div className="my-4 flex flex-col gap-2">
              <Input
                type="text"
                label={t('general.serialNumber')}
                name="serial_number"
                settings={{
                  placeholder: t('general.serialNumber'),
                  disabled: serialNumber !== '',
                }}
              />
            </div>
          )}
          {config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF && (
            <div className="my-4 flex flex-col gap-2">
              <SageFileUploader
                label={t('general.proofOfSerialNumber')}
                identifiers={{
                  category: UploadedFileCategories.SerialNumberProof,
                  docType: {
                    docid: holding_docid,
                    type: DocTypeShapeTypes.Holding,
                  },
                  personaId: newConsumerLink,
                }}
                maxFiles={1}
                onUploadCompleted={(files) =>
                  setFieldValue('serial_number_proof', files)
                }
                onDeleteFile={() => setFieldValue('serial_number_proof', [])}
                currentFiles={values.serial_number_proof ?? []}
                shouldAutoUpdateDocs={false}
                accepts={['image/*', '.pdf']}
              />
            </div>
          )}
          {config?.INSTALLATION_REQUIRED && (
            <div className="my-4 flex flex-col gap-2">
              <SageFileUploader
                label={t('general.supportingMaterials')}
                identifiers={{
                  category: UploadedFileCategories.installation_attachment,
                  docType: {
                    docid: holding_docid,
                    type: DocTypeShapeTypes.Holding,
                  },
                  personaId: newConsumerLink,
                }}
                onUploadCompleted={(files) =>
                  setFieldValue('installation_attachment', files)
                }
                onDeleteFile={() =>
                  setFieldValue('installation_attachment', [])
                }
                currentFiles={values.installation_attachment ?? []}
                shouldAutoUpdateDocs={false}
                accepts={['image/*', 'video/*', '.pdf']}
              />
            </div>
          )}
          {config?.HOLDINGS.NEW_REGISTRATION_FLOW.INTERNAL_COMMENT && (
            <div className="mt-3">
              <Input
                type="rich-text"
                label={t('general.internalComment')}
                name={'internal_comment'}
                settings={{
                  id: 'internal_comment',
                  allowSpecialCharacter: true,
                }}
              />
            </div>
          )}
          <div className="mt-5 flex w-full gap-4">
            <Button
              kind="primary"
              type="submit"
              loading={isSubmitting}
              disabled={
                Object.keys(errors).length > 1 || !formikRef.current?.isValid
              }
            >
              {config.HOLDINGS.NEW_REGISTRATION_FLOW.SUBMISSION_PREVIEW
                ? t('general.continue')
                : t('general.submit')}
            </Button>
            <Button kind="outline_red" type="submit" onClick={handleClose}>
              {t('general.cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default NewCreateHoldingForm;
